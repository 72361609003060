







import { mapState } from 'vuex';
import GameProviderReportingApiDoc from '@/components/game-provider/GameProviderReportingApiDoc.vue';
import GameProviderReportingApiKey from '@/components/game-provider/GameProviderReportingApiKey.vue';

export default {
  name: 'GameProviderReportingApiView',
  components: {
    GameProviderReportingApiDoc,
    GameProviderReportingApiKey
  },
  computed: {
    ...mapState('Auth', ['accountId'])
  }
};
